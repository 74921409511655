var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"password-view"},[_c('div',{class:[
      'level level-1',
      {
        green: _vm.form.password != '' && _vm.validPassword.strongNumber >= 1,
      },
    ]}),_c('div',{class:[
      'level level-2',
      {
        green: _vm.form.password != '' && _vm.validPassword.strongNumber >= 2,
      },
    ]}),_c('div',{class:[
      'level level-3',
      {
        green: _vm.form.password != '' && _vm.validPassword.strongNumber >= 3,
      },
    ]}),_c('div',{class:[
      'level level-4',
      {
        green: _vm.form.password != '' && _vm.validPassword.strongNumber >= 4,
      },
    ]}),_c('div',{class:[
      'level level-5',
      {
        green: _vm.form.password != '' && _vm.validPassword.strongNumber >= 5,
      },
    ]}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.password == ''),expression:"form.password == ''"}],staticClass:"scope-text"},[_vm._v("Score")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.form.password != '' &&
        (_vm.validPassword.strongNumber >= 0 && _vm.validPassword.strongNumber <= 2)
    ),expression:"\n      form.password != '' &&\n        (validPassword.strongNumber >= 0 && validPassword.strongNumber <= 2)\n    "}],staticClass:"scope-text red"},[_vm._v(" Fair ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.password != '' && (_vm.validPassword.strongNumber >= 3 && _vm.validPassword.strongNumber <= 4)),expression:"form.password != '' && (validPassword.strongNumber >= 3 && validPassword.strongNumber <= 4)"}],staticClass:"scope-text orange"},[_vm._v(" Good ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.password != '' && _vm.validPassword.strongNumber == 5),expression:"form.password != '' && validPassword.strongNumber == 5"}],staticClass:"scope-text green"},[_vm._v(" Very Strong ")]),_c('div',{staticClass:"requirements"},[_c('div',{class:['item eight-chars', { active: _vm.validPassword.isMinEightChar }]},[_c('div',{class:[
          'circle eight-chars-circle',
          { green: _vm.validPassword.isMinEightChar },
          { red: _vm.form.password.length != '' },
        ]}),_c('span',{staticClass:"eight-chars-text"},[_vm._v(" 8 Characters minimum ")])]),_c('div',{class:['item eight-chars', { active: _vm.validPassword.isMinOneUpper }]},[_c('div',{class:[
          'circle eight-chars-circle',
          { green: _vm.validPassword.isMinOneUpper },
          { red: _vm.form.password.length != '' },
        ]}),_c('span',{staticClass:"eight-chars-text"},[_vm._v(" 1 Uppercase letter ")])]),_c('div',{class:['item eight-chars', { active: _vm.validPassword.isMinOneLower }]},[_c('div',{class:[
          'circle eight-chars-circle',
          { green: _vm.validPassword.isMinOneLower },
          { red: _vm.form.password.length != '' },
        ]}),_c('span',{staticClass:"eight-chars-text"},[_vm._v(" 1 Lowercase letter ")])]),_c('div',{class:['item one-number', { active: _vm.validPassword.isMinOneNumber }]},[_c('div',{class:[
          'circle one-number-circle',
          { green: _vm.validPassword.isMinOneNumber },
          { red: _vm.form.password.length != '' },
        ]}),_c('span',{staticClass:"one-number-text"},[_vm._v(" 1 Number ")])]),_c('div',{class:[
        'item one-special-char',
        { active: _vm.validPassword.isMinOneSpecial },
      ]},[_c('div',{class:[
          'circle one-special-char-circle',
          { green: _vm.validPassword.isMinOneSpecial },
          { red: _vm.form.password.length != '' },
        ]}),_c('span',{staticClass:"one-special-char-text"},[_vm._v(" 1 Special character ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }